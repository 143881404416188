import React, { useRef, useState } from "react";
import orderBy from "lodash/orderBy";
import Skeleton from "@mui/material/Skeleton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import useAuth from "../../../../hooks/useAuth";
import ScanditScanner from "../../../../components/barcode/ScanditScanner/ScanditScanner";
import WarehouseModal from "../WarehouseModal";
import PopperComponent from "./PopperComponent";
import PaperComponent from "./PaperComponent";

export default function StorageLocationAutocomplete({
  index,
  value,
  name,
  loading,
  disabled,
  warehouseLocations,
  setFieldValue,
}) {
  const { user } = useAuth();
  const autocompleteRef = useRef();
  const [inputValue, setInputValue] = useState("");
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [openScan, setOpenScan] = useState(false);
  const number = index + 1;
  const allLocations = orderBy(warehouseLocations, v => v.locationId).map(v => ({
    warehouseId: v.warehouseId,
    warehouseName: v.warehouseName,
    locationName: v.locationName,
  }));
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const selectedValue =
    allLocations.filter(
      v => value.warehouse === v.warehouseId && value.rack_location?.includes(v.locationName)
    ) || [];

  const handleToggle = () => {
    setOpenWarehouse(v => !v);
  };

  const handleToggleScan = () => {
    setOpenScan(v => !v);
  };

  const handleSelect = v => {
    const rackLocation =
      value.rack_location && Array.isArray(value.rack_location) ? value.rack_location : [];
    setFieldValue(`${name}.${index}.warehouse`, v.warehouseId);
    setFieldValue(`${name}.${index}.rack_location`, rackLocation.concat(v.locationName));
  };

  const handleDetectedCode = code => {
    setTimeout(() => {
      autocompleteRef.current.click();
      openPopper();
      setInputValue(code);
      handleToggleScan();
    });
  };

  const handleInputChange = (e, value) => {
    if (e) {
      setInputValue(value);
    }
  };

  const handleChange = (e, value) => {
    if (value.length) {
      setFieldValue(`${name}.${index}.warehouse`, value[0].warehouseId);
      setFieldValue(
        `${name}.${index}.rack_location`,
        value.map(v => v.locationName)
      );
    } else {
      setFieldValue(`${name}.${index}.warehouse`, "");
      setFieldValue([]);
    }
  };

  return loading ? (
    <Skeleton variant="rounded" width="100%" height={39} />
  ) : (
    <>
      <Autocomplete
        ref={autocompleteRef}
        size="small"
        multiple
        disabled={disabled}
        options={allLocations}
        value={selectedValue}
        inputValue={inputValue}
        open={open}
        onOpen={openPopper}
        onClose={closePopper}
        onInputChange={handleInputChange}
        getOptionLabel={v => v.locationName}
        getOptionDisabled={v =>
          selectedValue.length > 0 && selectedValue[0].warehouseId !== v.warehouseId
        }
        onChange={handleChange}
        renderInput={params => <TextField {...params} label={`Storage Location ${number}`} />}
        ListboxProps={{ style: { paddingBottom: "300px" }, position: "bottom-start" }}
        PopperComponent={PopperComponent}
        PaperComponent={props => (
          <PaperComponent {...props} onToggle={handleToggle} onToggleScan={handleToggleScan} />
        )}
      />
      {openWarehouse && (
        <WarehouseModal open={openWarehouse} onClose={handleToggle} onSelect={handleSelect} />
      )}
      {openScan && (
        <ScanditScanner
          barcodeTrigger={user.company.settings.barcode_trigger}
          onDetected={handleDetectedCode}
          onClose={handleToggleScan}
        />
      )}
    </>
  );
}
