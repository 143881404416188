import React from "react";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import useAuth from "../../hooks/useAuth";
import CheckboxInput from "../../components/form/Checkbox";
import { isARMSTRONG, isATLAS, isDEMO, isPNC } from "../../utils/inventoryRequests";
import { shipments } from "../../constants/fields";

const useStyles = makeStyles(theme => ({
  fieldsColumn: {
    minWidth: 200,
    marginRight: 25,
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const defaultSetting =
  "identifiers,descriptive_info,origin_info,destination_info,warehouse_info,electrical_info,plumbing_info,mechanical_info,other_info,shipments,inventory_items,inventory_request,shopping_cart";

export default function IncludeFields({
  component,
  hideFields = [],
  descriptiveInfo,
  destinationInfo,
  electricalInfo,
  getOrders,
  getWarehouseInfo,
  identifiers,
  inventoryItems,
  inventoryRequest,
  mechanicalInfo,
  originInfo,
  otherInfo,
  plumbingInfo,
  reservations,
  getshoppingCart,
  storageEnabled = true,
  originEnabled = true,
  destinationEnabled = true,
  ordersTableFieldsEnabled = true,
  reservationsTableFieldsEnabled = true,
}) {
  const classes = useStyles();
  const { user } = useAuth();
  const { company } = user;
  const inventoryTrackingEnabled = user.company.settings.inventory_tracking;
  const medicalFieldsEnabled = user.company.settings.medical_fields;
  const reservationsEnabled = user.company.settings.reservations;

  // Fields to hide based on the inventory_tracking toggle
  const InventorytrackingHideFields = !inventoryTrackingEnabled
    ? [
        "warehouse_info",
        "inventory_items",
        "inventory_request",
        "shopping_cart",
        "orders",
        "reservations",
      ]
    : [];

  // Fields to hide based on the medical fields toggle
  const MedicalHideFields = !medicalFieldsEnabled
    ? ["electrical_info", "plumbing_info", "mechanical_info"]
    : [];
  
  const ReservationsHideFields = !reservationsEnabled
    ? ["reservations"]
    : [];

  const combinedHideFields = [...hideFields, ...InventorytrackingHideFields, ...MedicalHideFields, ...ReservationsHideFields];

  const columns = (company.settingsConfig || defaultSetting).split(",");
  const settingsList =
    isPNC || isATLAS || isARMSTRONG || isDEMO
      ? columns.filter(v => !["plumbing_info", "mechanical_info", "other_info"].includes(v))
      : columns;
  const settingsConfig = settingsList.filter(v => !combinedHideFields.includes(v));

  const warehouseInfo = getWarehouseInfo({
    availableQuantity: user.company.settings.quantity_label,
  });
  const orders = getOrders({ dateName: user.company.settings.date_name });
  const shoppingCart = getshoppingCart({
    ...user.company.settings,
    projectLabel: `Asssociated ${user.company.settings.filter_field2}`,
    quantityLabel: user.company.settings.quantity_label,
  });
  const CheckboxComponent = component || CheckboxInput;

  const Checkbox = props => {
    return <CheckboxComponent {...props} />;
  };

  return (
    <>
      <Box display="flex" sx={{ overflowY: "auto" }}>
        {settingsConfig.includes("identifiers") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Identifiers</Typography>
            </Box>
            <Box>
              {identifiers.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("descriptive_info") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Descriptive</Typography>
            </Box>
            <Box>
              {descriptiveInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("warehouse_info") && storageEnabled && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Storage</Typography>
            </Box>
            <Box>
              {warehouseInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("origin_info") && originEnabled && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Origin</Typography>
            </Box>
            <Box>
              {originInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("destination_info") && destinationEnabled && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Destination</Typography>
            </Box>
            <Box>
              {destinationInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("electrical_info") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Electrical</Typography>
            </Box>
            <Box>
              {electricalInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("plumbing_info") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Plumbing</Typography>
            </Box>
            <Box>
              {plumbingInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("mechanical_info") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Mechanical</Typography>
            </Box>
            <Box>
              {mechanicalInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("other_info") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Miscellaneous</Typography>
            </Box>
            <Box>
              {otherInfo.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("shipments") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Shipment Fields</Typography>
            </Box>
            <Box>
              {shipments.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("inventory_items") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Order Items Fields</Typography>
            </Box>
            <Box>
              {inventoryItems.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("inventory_request") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Edit Order Fields</Typography>
            </Box>
            <Box>
              {inventoryRequest.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {settingsConfig.includes("shopping_cart") && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Store Card Fields</Typography>
            </Box>
            <Box>
              {shoppingCart.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {!combinedHideFields.includes("orders") && ordersTableFieldsEnabled && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Orders Table Fields</Typography>
            </Box>
            <Box>
              {orders.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
        {!combinedHideFields.includes("reservations") && reservationsTableFieldsEnabled && (
          <Box className={classes.fieldsColumn}>
            <Box my={1}>
              <Typography color="textSecondary">Reservations Table Fields</Typography>
            </Box>
            <Box>
              {reservations.map(([name, category, label]) => (
                <Checkbox key={name} name={name} category={category} label={label} />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
