export default {
  photo: "Asset Image",
  barcode: "Barcode",
  qr_code_image: "QR Code Image",
  id: "ID",
  item_id: "Item ID",
  asset_id: "Asset ID",
  barcode_number: "Barcode Number",
  disposition: "Disposition",
  description: "Description",
  description_comments: "Description Comments",
  manufacturer: "Manufacturer",
  color: "Color",
  finishes: "Finish",
  condition: "Condition",
  dimensions: 'Dimensions (W" x D" x H")',
  existing_building: "Origin Building",
  existing_floor: "Origin Floor",
  existing_room: "Origin Room Name",
  origin_room_number: "Origin Room Number",
  origin_cubby_number: "Origin Cubby Number",
  origin_employee_name: "Origin Employee Name",
  quantity: "Origin Quantity",
  origin_comments: "Origin Comments",
  new_building: "Destination Building",
  new_floor: "Destination Floor",
  new_room: "Destination Room Name",
  destination_employee_name: "Destination Employee Name",
  destination_room_number: "Destination Room Number",
  destination_quantity: "Destination Quantity",
  destination_comments: "Destination Comments",
  status: "Status",
  checkin: "Checkin",
  checkout: "Checkout",
  checkout_date: "Checkout Date",
  transferred_by: "Transferred By",
  comments: "Comments",
  signature: "Signature",
  received_by_email: "Received by email",
  received_by_date: "Received by date",
  checkin_agent: "Checkin Agent",
  checkin_status: "Checkin Status",
  checkin_date: "Checkin Date",
  checkout_agent: "Checkout Agent",
  checkout_status: "Checkout Status",
  requested_on: "Requested On",
  started_on: "Started On",
  completed_on: "Completed On",
  destination_room_numbers: "Destination Room Numbers",
  inventory_items_disposition: "Inventory Item Disposition",
  // Medical fields
  department_name: "Department Name",
  lab_name: "Lab Name",
  capital_asset_number: "Capital Asset Number",
  serial_number: "Serial Number",
  vendor_required: "Vendor Required (Yes or No)",
  destination_number: "Destination No",
  install_responsibility: "Install Responsibility",
  bench_floor_hood_wall_bsc: "Install Location",
  siesmic_anchor_required: "Siesmic Anchor Required",
  volts: "Volts",
  amps_watts: "Amps / Watts",
  btu: "BTU (Output)",
  hertz: "HERTZ",
  phase: "Phase",
  nema_config: "NEMA Config",
  plug_quantity: "Plug Quantity",
  standby_power: "Standby Power",
  normal_power: "Normal Power",
  dedicated_power: "Dedicated Power",
  ups: "UPS",
  standby_power_sb: "Standby Power (SB)",
  plug_connection: "Plug Connection",
  hse_co: "HSE CO2",
  local_gas_tanks: "Local Gas Tanks (H2, Ar, He, Meth, other)",
  hse_vac: "HSE VAC",
  local_vac_pump: "Local VAC (Pump)",
  icw: "ICW",
  ihw: "IHW",
  pcw_supply: "PCW (Supply)",
  pcw_return: "PCW (Return)",
  ro: "RO",
  di: "DI",
  floor_sink: "Floor Sink",
  floor_drain: "Floor Drain",
  solve_waste_coll: "Solve Waste Coll",
  exh_header: "EXH Header",
  vac_pump_vent_plumbing: "VAC PUMP VENT",
  exh_ceiling_blast_gate: "EXH Ceiling w/ Blast Gate",
  exh_snorkel_arm_plumbing: "EXH SNORKEL ARM (Plumbing)",
  exh_other: "EXH Other",
  list_exh_type: "List EXH Type",
  exhaust: "Exhaust",
  vac_pump_vent_mechanical: "VAC PUMP VENT (Mechanical)",
  exh_snorkel_arm_mechanical: "EXH SNORKEL ARM (Mechanical)",
  cfm: "CFM",
  data_port_qty: "Data Port (Quantity)",
  bms: "BMS",
  other_utility: "Other Utility",
  move_notes: "Move Notes",
  warehouse_location: "Rack Location",
  rack_locations: "Storage Location(s)",
  pallet_location: "Pallet Location",
  vault_location: "Vault Location",
  available_quantity: "Available Quantity",
  quantity_received: "Quantity Received",
  reserved_quantity: "Reserved Quantity",
  minimum_quantity: "Minimum Quantity",
  quantity_ordered: "Quantity Ordered",
  model: "Model",
  tss_number: "TSS Number",
  ase: "ASE",
  attachments: "Attachments",
  price: "Price",
  ii_image: "Asset Image",
  ii_barcode: "Barcode",
  ii_description: "Description",
  ii_qtyRequested: "Quantity Requested",
  ii_po_number: "PO Number",
  ii_idsr_number: "IDSR #",
  ii_eoc_number: "EOC",
  ii_location: "Delivery Location",
  ii_location_address: "Location Address",
  ii_building: "Building",
  ii_floor: "Floor",
  ii_room: "Room",
  ii_deliveryDate: "Delivery Date",
  ii_deliveryTime: "Delivery Time",
  ii_notes: "Notes",
  ii_dimensions: "Dimensions",
  ir_email: "Created By",
  ir_cc_emails: "CC Emails",
  ir_work_order_number: "Order Number",
  ir_location: "Delivery Location",
  ir_location_address: "Location Address",
  ir_po_number: "PO Number",
  ir_resources: "Resources",
  ir_comment: "Comment",
  ir_designer: "Requestor",
  ir_start_date: "Start Date",
  ir_start_time: "Start Time",
  ir_end_date: "End Date",
  ir_end_time: "End Time",
  ir_delivery_date: "Delivery Date",
  sc_description: "Description",
  sc_model: "Model",
  sc_storage_location: "Storage Location",
  sc_item_id_overlay: "Item ID Overlay",
  sc_dimensions: "Dimensions",
  sc_available_qty: "Available Quantity",
  sc_price: "Price",
  // sc_asset_id_overlay: "Asset id overlay",
  sc_manufacture: "Manufacturer",
  sc_color: "Color",
  sc_condition: "Condition",
  sc_item_code: "Item Code",
  item_code: "Item Code",
  unit_cost: "Unit Cost",
  warranty: "Warranty",
  category: "Category",
  subcategory: "Subcategory",
  order_work_order_number: "Order #",
  order_requested_at: "Requested At",
  order_requested_by: "Requested By",
  order_start_date: "Start Date",
  order_delivery_date: "Delivery Date",
  order_status: "Status",
  order_destination: "Destination",
  order_pdf_report: "PDF Report",
  order_va_status: "VA Status",
  order_actions: "Actions",
  reservation_order_number: "Reservation #",
  reservation_create_on: "Created On",
  reservation_age: "Reservation Age",
  reservation_for: "Reserved For",
  reservation_po_number: "PO#",
  reservation_notes: "Notes",
  reservation_actions: "Actions",
  power: "Power",
  weight: "Unit Weight (lbs)",
  po_number: "PO Number",
  warehouse_quantity: "Quantity in Warehouse",
  shipment_id: "Shipment ID",
  vendor_name: "Vendor Name",
  carrier_name: "Carrier Name",
  order_number: "Order Number",
  bol: "BOL",
  total_weight: "Total Weight",
  shipment_notes: "Notes",
  tracking_number: "Tracking Number",
  customer_po_number: "Customer PO Number",
  total_receiving_units: "Total Receiving Units",
  receiving_report_number: "Receiving Report Number",
  pallet_type: "Pallet Type",
  created_at: "Created On",
  updated_at: "Updated On",
};

export const benchFloorHoodWallBscOptions = [
  ["Bench", "Bench"],
  ["Floor", "Floor"],
  ["Wall", "Wall"],
  ["BSC", "BSC"],
];

export const voltsOptions = [
  ["115", "115"],
  ["120", "120"],
  ["208", "208"],
  ["220", "220"],
];

export const phaseOptions = [
  ["1ph", "1 ph"],
  ["3ph", "3 ph"],
];

export const nemaConfigOptions = [
  ["5-15P", "5-15P"],
  ["5-20P", "5-20P"],
  ["6-15P", "6-15P"],
  ["6-20P", "6-20P"],
  ["L5-20P", "L5-20P"],
  ["L6-20P", "L6-20P"],
  ["6-30P", "6-30P"],
  ["L6-30P", "L6-30P"],
];

export const plugConnectionOptions = [
  ["Standard", "Standard"],
  ["Hard Wired", "Hard Wired"],
  ["Hard Disconnect", "Hard Disconnect"],
  ["J- Box", "J- Box"],
  ["Special", "Special"],
];

export const localGasTanksOptions = [
  ["H2", "H2"],
  ["CA", "CA"],
  ["He", "He"],
  ["Meth", "Meth"],
  ["Argon", "Argon"],
  ["N2", "N2"],
  ["LN2", "LN2"],
  ["SP MIX", "SP MIX"],
];

export const solveWasteCollOptions = [
  ["Carboy Container", "Carboy Container"],
  ["House Plumbing", "House Plumbing"],
];

export const exhHeaderOptions = [
  ["Exh Header", "Exh Header"],
  ["Vac Pump", "Vac Pump"],
  ["Vent", "Vent"],
  ["Exh Ceiling w/blast gate", "Exh Ceiling w/blast gate"],
];

export const bmsOptions = [
  ["Recorder", "Recorder"],
  ["Alarm", "Alarm"],
  ["LabWatch", "LabWatch"],
];
