import React from "react";
import Box from "@material-ui/core/Box";

import { replaceCollection } from "../../../components/TablePro/utils";
import ActionModal from "../../../components/modals/action-modal";
import useMutation from "../../../hooks/useMutation";
import { CREATE_INVENTORY_TRANSACTION_MUTATION } from "../../../constants/graphql";
import { ASSET_REPLAY_LOGS_QUERY, ASSET_AUDIT_LOGS_QUERY } from "../../../constants/graphql/queries";
import { useAssetsCollection } from "../../../contexts/assetsCollection";
import RackLocationsForm from "./RackLocationsForm";
import useAuth from "src/hooks/useAuth";

export default function RackLocationsModal({ open, toggleModal, entity, addAction }) {
  const { setCollection, setLoadingMutation } = useAssetsCollection();
  const { user } = useAuth();
  const [createTransactionMutation] = useMutation(CREATE_INVENTORY_TRANSACTION_MUTATION);

  const handleSave = values => {
    setLoadingMutation(true);

    const locations = values.rackLocations.map(location => ({
      sub_qty: location.sub_qty,
      rack_location: location.rack_location,
      warehouse: location.warehouse,
    }));

    const transactionPayload = {
      code: entity.assetId || "",
      notes: values.notes || "",
      senderName: values.senderName || "",
      carrierName: values.carrierName || "",
      trackingNumber: values.trackingNumber || "",
      orderNumber: values.orderNumber || "",
      poNumber: values.poNumber || "",
      quantity: values.quantity || 0,
      rackLocations: locations,
      rackLocationsChanges: [],
    };

    const refetchQueries = [
      user.company.settings.asset_replay_logs
        ? {
            query: ASSET_REPLAY_LOGS_QUERY,
            variables: { assetId: entity.id },
          }
        : {
            query: ASSET_AUDIT_LOGS_QUERY,
            variables: { assetId: entity.id, action: "replenish_inventory" },
          },
    ];

    createTransactionMutation({
      variables: transactionPayload,
      refetchQueries,
      onSuccess: () => {
        setLoadingMutation(false);
        toggleModal();
        if (addAction) {
          addAction([
            {
              [entity.id]: {
                rackLocations: entity.rackLocations,
              },
            },
            { [entity.id]: values },
          ]);
        }
      },
      onFailure: error => {
        setLoadingMutation(false);
        console.error("Error creating transaction:", error);
      },
    });
  };

  return open ? (
    <ActionModal
      open
      title="Storage Location(s)"
      maxWidth={640}
      onClose={toggleModal}
      disableTransition
    >
      <Box mt={1} />
      <RackLocationsForm
        asset={entity}
        rackLocations={entity.rackLocations || []}
        toggleModal={toggleModal}
        onSubmit={handleSave}
      />
    </ActionModal>
  ) : null;
}
