import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {useQuery} from "@apollo/react-hooks";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {ClientsProvider} from "src/contexts/clients";
import {INVENTORY_REQUEST_QUERY} from "src/constants/graphql/queries";
import useAuth from "src/hooks/useAuth";
import ManualLocationItem from "src/views/inventory/requests/orders/ManualLocationItem";

export default function ManualLocationCheckout({ inventoryRequestId, onClose }) {
  const { user } = useAuth();
  const { loading, data: { inventoryRequest = {} } = {} } = useQuery(INVENTORY_REQUEST_QUERY, {
    variables: { id: inventoryRequestId },
    fetchPolicy: "network-only",
  });
  const inventoryItems = inventoryRequest.inventoryItems || [];

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box width={40} />
          <Typography fontWeight={500} fontSize={22} color="textPrimary" component="span">
            Order Picking
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 200 }}>
        <DialogContentText>
          {loading ? (
            <Box width="100%" display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : inventoryItems.length === 0 ? (
            <Box pt={7} display="flex" justifyContent="center">
              No items to pick
            </Box>
          ) : (
            <ClientsProvider>
              <Table>
                <TableHead>
                  <TableCell align="center">Image</TableCell>
                  <TableCell>Item ID</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>{user.company.settings.quantity_label}</TableCell>
                  <TableCell>Storage Location(s)</TableCell>
                  <TableCell align="right" width={160}>
                    Pick Status
                  </TableCell>
                </TableHead>
                <TableBody>
                  {inventoryItems.map(({ asset, ...item }, index) => (
                    <ManualLocationItem key={index} asset={asset} item={item} />
                  ))}
                </TableBody>
              </Table>
            </ClientsProvider>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
