import sortBy from "lodash/sortBy";

import labels from "./displayFields";

export const getIdentifiers = values =>
  sortBy(
    [
      ["photo", "include", labels.photo],
      ["item_id", "include", labels.item_id],
      // ["asset_id", "include", labels.asset_id],
      ["barcode_number", "include", values.barcode_label || labels.barcode_number],
      ["attachments", "include", labels.attachments],
      ["item_code", "include", labels.item_code],
      [
        "capital_asset_number",
        "descriptive_info",
        values.capital_asset_number_label || labels.capital_asset_number_label,
      ],
      ["serial_number", "descriptive_info", values.serial_number_label || labels.serial_number],
      ["tss_number", "descriptive_info", values.tss_number_label || labels.tss_number],
      ["receiving_report_number", "descriptive_info", labels.receiving_report_number],
      ["created_at", "include", labels.created_at],
      ["updated_at", "include", labels.updated_at],
    ],
    v => v[2]
  );

export const getDescriptiveInfo = values =>
  sortBy(
    [
      ["description", "descriptive_info", labels.description],
      [
        "description_comments",
        "descriptive_info",
        values.description_comments_label || labels.description_comments,
      ],
      ["manufacturer", "descriptive_info", labels.manufacturer],
      ["color", "descriptive_info", labels.color],
      ["finishes", "descriptive_info", labels.finishes],
      ["condition", "descriptive_info", labels.condition],
      ["dimensions", "descriptive_info", labels.dimensions],
      ["model", "descriptive_info", values.model_label || labels.model],
      ["power", "plumbing_info", "Power"],
      ["warranty", "plumbing_info", labels.warranty],
      ["unit_cost", "plumbing_info", labels.unit_cost],
      ["category", "include", labels.category],
      ["disposition", "include", labels.disposition],
      ["subcategory", "include", labels.subcategory],
      ["weight", "include", labels.weight],
    ],
    v => v[2]
  );

export const originInfo = sortBy(
  [
    ["existing_building", "origin_info", labels.existing_building],
    ["existing_floor", "origin_info", labels.existing_floor],
    ["existing_room", "origin_info", labels.existing_room],
    ["origin_room_number", "origin_info", labels.origin_room_number],
    ["origin_cubby_number", "origin_info", labels.origin_cubby_number],
    ["origin_employee_name", "origin_info", labels.origin_employee_name],
    ["quantity", "origin_info", labels.quantity],
    // ["price", "origin_info", labels.price],
    ["origin_comments", "origin_info", labels.origin_comments],
    ["department_name", "origin_info", labels.department_name],
    ["lab_name", "origin_info", labels.lab_name],
  ],
  v => v[2]
);

export const destinationInfo = sortBy(
  [
    ["new_building", "destination_info", labels.new_building],
    ["new_floor", "destination_info", labels.new_floor],
    ["new_room", "destination_info", labels.new_room],
    ["destination_employee_name", "destination_info", labels.destination_employee_name],
    ["destination_room_number", "destination_info", labels.destination_room_number],
    ["destination_quantity", "destination_info", labels.destination_quantity],
    ["destination_comments", "destination_info", labels.destination_comments],
    ["vendor_required", "destination_info", labels.vendor_required],
    // ["destination_number", "destination_info", labels.destination_number],
    ["install_responsibility", "destination_info", labels.install_responsibility],
    ["bench_floor_hood_wall_bsc", "destination_info", labels.bench_floor_hood_wall_bsc],
    ["siesmic_anchor_required", "destination_info", labels.siesmic_anchor_required],
  ],
  v => v[2]
);

export const getWarehouseInfo = ({ availableQuantity }) =>
  sortBy(
    [
      ["rack_locations", "warehouse_info", labels.rack_locations],
      // ["pallet_location", "warehouse_info", labels.pallet_location],
      // ["vault_location", "warehouse_info", labels.vault_location],
      ["available_quantity", "warehouse_info", availableQuantity],
      ["quantity_ordered", "warehouse_info", labels.quantity_ordered],
      // ["quantity_received", "warehouse_info", labels.quantity_received],
      ["po_number", "warehouse_info", labels.ii_po_number],
      ["reserved_quantity", "warehouse_info", labels.reserved_quantity],
      ["minimum_quantity", "warehouse_info", labels.minimum_quantity],
      ["warehouse_quantity", "warehouse_info", labels.warehouse_quantity],
      ["pallet_type", "warehouse_info", labels.pallet_type],
    ],
    v => v[2]
  );

export const electricalInfo = sortBy(
  [
    ["volts", "electrical_info", labels.volts],
    ["amps_watts", "electrical_info", labels.amps_watts],
    ["btu", "electrical_info", labels.btu],
    ["hertz", "electrical_info", labels.hertz],
    ["phase", "electrical_info", labels.phase],
    ["nema_config", "electrical_info", labels.nema_config],
    ["plug_quantity", "electrical_info", labels.plug_quantity],
    ["standby_power", "electrical_info", labels.standby_power],
    ["normal_power", "electrical_info", labels.normal_power],
    ["dedicated_power", "electrical_info", labels.dedicated_power],
    ["ups", "electrical_info", labels.ups],
    ["standby_power_sb", "electrical_info", labels.standby_power_sb],
    ["plug_connection", "electrical_info", labels.plug_connection],
  ],
  v => v[2]
);

export const plumbingInfo = sortBy(
  [
    ["hse_co", "plumbing_info", labels.hse_co],
    ["local_gas_tanks", "plumbing_info", labels.local_gas_tanks],
    ["hse_vac", "plumbing_info", labels.hse_vac],
    ["local_vac_pump", "plumbing_info", labels.local_vac_pump],
    ["icw", "plumbing_info", labels.icw],
    ["ihw", "plumbing_info", labels.ihw],
    ["pcw_supply", "plumbing_info", labels.pcw_supply],
    ["pcw_return", "plumbing_info", labels.pcw_return],
    ["ro", "plumbing_info", labels.ro],
    ["di", "plumbing_info", labels.di],
    ["floor_sink", "plumbing_info", labels.floor_sink],
    ["floor_drain", "plumbing_info", labels.floor_drain],
    ["solve_waste_coll", "plumbing_info", labels.solve_waste_coll],
    ["exh_header", "plumbing_info", labels.exh_header],
    ["vac_pump_vent_plumbing", "plumbing_info", labels.vac_pump_vent_plumbing],
    ["exh_ceiling_blast_gate", "plumbing_info", labels.exh_ceiling_blast_gate],
    ["exh_snorkel_arm_plumbing", "plumbing_info", labels.exh_snorkel_arm_plumbing],
    ["exh_other", "plumbing_info", labels.exh_other],
    ["list_exh_type", "plumbing_info", labels.list_exh_type],
  ],
  v => v[2]
);

export const mechanicalInfo = sortBy(
  [
    ["exhaust", "mechanical_info", labels.exhaust],
    ["vac_pump_vent_mechanical", "mechanical_info", labels.vac_pump_vent_mechanical],
    ["exh_snorkel_arm_mechanical", "mechanical_info", labels.exh_snorkel_arm_mechanical],
    ["cfm", "mechanical_info", labels.cfm],
    ["ase", "mechanical_info", labels.ase],
  ],
  v => v[2]
);

export const getOtherInfo = values =>
  sortBy(
    [
      ["data_port_qty", "other_info", values.data_port_qty_label || labels.data_port_qty],
      ["bms", "other_info", values.bms_label || labels.bms],
      ["other_utility", "other_info", values.other_utility_label || labels.other_utility],
      ["move_notes", "other_info", values.move_notes_label || labels.move_notes],
    ],
    v => v[2]
  );

export const shipments = sortBy(
  [
    ["shipment_id", "shipments", labels.shipment_id],
    ["vendor_name", "shipments", labels.vendor_name],
    ["carrier_name", "shipments", labels.carrier_name],
    ["order_number", "shipments", labels.order_number],
    ["bol", "shipments", labels.bol],
    ["total_weight", "shipments", labels.total_weight],
    ["shipment_notes", "shipments", labels.shipment_notes],
    ["tracking_number", "shipments", labels.tracking_number],
    ["customer_po_number", "shipments", labels.customer_po_number],
    ["total_receiving_units", "shipments", labels.total_receiving_units],
  ],
  v => v[2]
);

export const getInventoryItems = values =>
  sortBy(
    [
      ["ii_image", "inventory_items", labels.ii_image],
      ["ii_barcode", "inventory_items", values.barcode_label || labels.ii_barcode],
      ["ii_description", "inventory_items", labels.ii_description],
      ["ii_qtyRequested", "inventory_items", labels.ii_qtyRequested],
      ["ii_po_number", "inventory_items", labels.ii_po_number],
      ["ii_idsr_number", "inventory_items", labels.ii_idsr_number],
      // ["ii_eoc_number", "inventory_items", labels.ii_eoc_number],
      ["ii_location", "inventory_items", labels.ii_location],
      // ["ii_location_address", "inventory_items", labels.ii_location_address],
      ["ii_building", "inventory_items", labels.ii_building],
      ["ii_floor", "inventory_items", labels.ii_floor],
      ["ii_room", "inventory_items", labels.ii_room],
      // ["ii_deliveryDate", "inventory_items", labels.ii_deliveryDate],
      // ["ii_deliveryTime", "inventory_items", labels.ii_deliveryTime],
      ["ii_notes", "inventory_items", labels.ii_notes],
      ["ii_item_id", "inventory_items", labels.item_id],
      ["ii_model", "inventory_items", values.model_label || labels.model],
      ["ii_dimensions", "inventory_items", labels.ii_dimensions],
      ["ii_disposition", "inventory_items", labels.disposition],
    ],
    v => v[2]
  );

export const inventoryRequest = sortBy(
  [
    ["ir_email", "inventory_items", labels.ir_email],
    // ["ir_cc_emails", "inventory_items", labels.ir_cc_emails],
    ["ir_work_order_number", "inventory_items", labels.ir_work_order_number],
    ["ir_po_number", "inventory_items", labels.ir_po_number],
    ["ir_location", "inventory_items", labels.ir_location],
    ["ir_location_address", "inventory_items", labels.ir_location_address],
    ["ir_resources", "inventory_items", labels.ir_resources],
    ["ir_comment", "inventory_items", labels.ir_comment],
    ["ir_designer", "inventory_items", labels.ir_designer],
    ["ir_start_date", "inventory_items", labels.ir_start_date],
    ["ir_start_time", "inventory_items", labels.ir_start_time],
    ["ir_end_date", "inventory_items", labels.ir_end_date],
    ["ir_end_time", "inventory_items", labels.ir_end_time],
    ["ir_delivery_date", "inventory_items", labels.ir_delivery_date],
    ["ir_disposition", "inventory_items", labels.disposition],
  ],
  v => v[2]
);

export const getshoppingCart = ({ projectLabel, quantityLabel, ...values }) =>
  sortBy(
    [
      ["sc_description", "inventory_items", labels.sc_description],
      ["sc_model", "inventory_items", values.model_label || labels.sc_model],
      ["sc_po_number", "warehouse_info", labels.ii_po_number],
      ["sc_storage_location", "inventory_items", projectLabel || labels.sc_storage_location],
      ["sc_item_id_overlay", "inventory_items", labels.sc_item_id_overlay],
      ["sc_dimensions", "inventory_items", labels.sc_dimensions],
      ["sc_available_qty", "inventory_items", quantityLabel || labels.sc_available_qty],
      // ["sc_price", "inventory_items", labels.sc_price],
      // ["sc_asset_id_overlay", "inventory_items", labels.sc_asset_id_overlay],
      ["sc_manufacture", "inventory_items", labels.sc_manufacture],
      ["sc_color", "inventory_items", labels.sc_color],
      ["sc_condition", "inventory_items", labels.sc_condition],
      ["sc_item_code", "inventory_items", labels.sc_item_code],
    ],
    v => v[2]
  );

export const getOrders = ({ dateName }) =>
  sortBy(
    [
      ["order_work_order_number", "orders", labels.order_work_order_number],
      ["order_requested_at", "orders", labels.order_requested_at],
      ["order_requested_by", "orders", labels.order_requested_by],
      ["order_start_date", "orders", labels.order_start_date],
      ["order_status", "orders", labels.order_status],
      ["order_destination", "orders", labels.order_destination],
      ["order_pdf_report", "orders", labels.order_pdf_report],
      ["order_va_status", "orders", labels.order_va_status],
      ["order_actions", "orders", labels.order_actions],
      ["order_delivery_date", "orders", dateName || labels.order_delivery_date],
    ],
    v => v[2]
  );

export const reservations = sortBy(
  [
    ["reservation_order_number", "reservation", labels.reservation_order_number],
    ["reservation_create_on", "reservation", labels.reservation_create_on],
    ["reservation_age", "reservation", labels.reservation_age],
    ["reservation_for", "reservation", labels.reservation_for],
    // ["reservation_po_number", "reservation", labels.reservation_po_number],
    ["reservation_notes", "reservation", labels.reservation_notes],
    ["reservation_actions", "reservation", labels.reservation_actions],
  ],
  v => v[2]
);
